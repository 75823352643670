import React from 'react';
import { Select as MaterialSelect, MenuItem as MaterialMenuItem, FormLabel, SelectChangeEvent } from '@mui/material';
import { FinePrint } from '../typography';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

type SelectProps<T> = {
    label: string;
    options: { label: string; value: T }[];
    value: T | undefined;
    onChange: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

const useStyles = makeStyles<OnrampTheme>(theme => ({
    label: {
        width: '100%',
        textAlign: 'left',
    },
    default: { width: '100%', marginBottom: theme.spacing(1) },
    wrapper: { marginBottom: theme.spacing(0.5) },
}));

export const Select = <T extends string | number>({
    label,
    options,
    value,
    onChange,
    onBlur,
    disabled,
}: SelectProps<T>) => {
    const styles = useStyles();

    return (
        <div className={styles.wrapper}>
            <FormLabel>
                <FinePrint>{label}</FinePrint>
            </FormLabel>

            <MaterialSelect
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className={styles.default}
                disabled={disabled}
            >
                {options.map((o, i) => (
                    <MaterialMenuItem key={i} value={o.value}>
                        {o.label}
                    </MaterialMenuItem>
                ))}
            </MaterialSelect>
        </div>
    );
};
