import React from 'react';
import { InfoCard, Content } from '@backstage/core-components';
import { useOnrampTheme } from '../../../theme';
import randomColor from 'randomcolor';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CostDisplayData } from '../types';
import { groupBy } from 'lodash';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

type AzureCostBarGraphProps = {
    title: string;
    data: CostDisplayData[];
};

export const AzureCostBarGraph = ({ title, data }: AzureCostBarGraphProps) => {
    const theme = useOnrampTheme();

    const discreteNames = [ ...new Set(data.map(val => val.identifier) ?? []) ].sort();

    const colors = (): string[] => {
        const colorArr = [];
        const hues = theme.palette.charts.hues;
        const numPerHue = Math.ceil(discreteNames.length / hues.length );

        for (let i = 0; i <= discreteNames.length; i += numPerHue) {
            const randomColors = randomColor({
                count: numPerHue,
                hue: hues[i],
            });
            colorArr.push(...randomColors);
        }
        return colorArr;
    };

    const legendItems = discreteNames.map(function createLegendObjs(val, index) {
        return {
            title: val,
            color: colors()[index],
            strokeWidth: 30,
        };
    });

    const datasets = Object.entries(groupBy(data, x => x.identifier)).map(([name, values], index) => {
        const colorForName = legendItems.filter(item => { return item.title === name });

        return {
            id: index,
            label: name,
            backgroundColor: colorForName[0].color,
            data: values.map(val => ({
                x: val.date.toFormat('MM/dd/yy'),
                y: val.amount,
            })),
        };
    });

    return (
        <InfoCard title={title} variant="gridItem">
            <Content>
                <Bar
                    height={100}
                    options={{
                        plugins: {
                            legend: {
                                position: 'bottom',
                                labels: {
                                    generateLabels: () => {
                                        return datasets.map(
                                            (item, i) => ({
                                                text: item.label,
                                                fillStyle:
                                                    item.backgroundColor,
                                                lineWidth: 0,
                                                datasetIndex: i,
                                            }),
                                        );
                                    },
                                },
                                onClick: (e) => e.native?.stopPropagation()
                            },
                        },
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                            },
                        },
                    }}
                    data={{
                        datasets,
                    }}
                />
            </Content>
        </InfoCard>
    );
};
