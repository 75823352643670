import { DiscoveryApi, FetchApi, IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';
import { ResponseError } from '@backstage/errors';
import { ApiModel, SubscriptionModel } from '../../../../../backend/src/customPlugins/apim/types';

export interface ApimClientApi {
    getApimApi(apiName: string, environment: string): Promise<ApiModel>;
    createApimApi(api: ApiModel, environment: string): Promise<number>;
    updateApimApi(api: ApiModel, environment: string): Promise<number>;
    getApimApis(environment: string): Promise<string[]>;
    createApimSubscription(subscription: SubscriptionModel, environment: string): Promise<number>;
}

export class ApimClient implements ApimClientApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly fetchApi: FetchApi;

    constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi; fetchApi?: FetchApi }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.fetchApi = options.fetchApi || { fetch: crossFetch };
    }

    async getApimApi(apiName: string, environment: string): Promise<ApiModel> {
        const response = await this.request('GET', `/apis/${environment}/${apiName}`);
        return response.body;
    }
    async createApimApi(apiModel: ApiModel, environment: string): Promise<number> {
        const response = await this.request('POST', `/apis/${environment}`, apiModel);
        return response.response.status;
    }

    async updateApimApi(apiModel: ApiModel, environment: string): Promise<number> {
        const response = await this.request('PUT', `/apis/${environment}`, apiModel);
        return response.response.status;
    }

    async getApimApis(environment: string): Promise<string[]> {
        const response = await this.request('GET', `/apis/${environment}`);
        return response.body;
    }

    async createApimSubscription(subscription: SubscriptionModel, environment: string): Promise<number> {
        const response = await this.request('POST', `/subscriptions/${environment}`, subscription);
        return response.response.status;
    }

    private async request(
        method: string,
        path: string,
        body?: any,
        additionalHeaders?: Record<string, string>,
    ): Promise<{ body: any; response: Response }> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl('apim')}`;

        const token = (await this.identityApi.getCredentials()).token;
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...(additionalHeaders ?? {}),
        };

        const response = await this.fetchApi.fetch(`${baseUrl}${path}`, {
            method,
            headers,
            body: body === undefined ? undefined : JSON.stringify(body),
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        try {
            return {
                body: await response.json(),
                response,
            };
        } catch {
            return { body: undefined, response };
        }
    }
}

export const apimApiRef = createApiRef<ApimClientApi>({
    id: 'apim',
});

