import {
    ApplicationProvisioningRequest,
    UserInfo,
    ResourceNeedType,
    CIOnlyResourceGroup,
    AzureResourcesLanguage,
    SecurityGroupInfo,
} from '../../../types/template-request';
import { PackageRequest } from '../../../types/package';
import { ApigeeRequest } from '../../../types/apigee';
import { SalesforceDxRequest } from '../../../types/salesforcedx';
import { ConsoleApplicationRequest } from '../../../types/console';
import { ApplicationTransferRequest } from '../../../types/applicationTransfer';
import { CloudAccountRequest } from '../../../types/cloudAccount';
import { AzureSecurityGroupsRequest } from '../../../types/azureSecurityGroups';
import { AzureTaggingRequest } from '../../../types/azureTagging';
import { IamRequest } from '../../../types/iamRequest';
import { ServicePrincipalRequest } from '../../../types/servicePrincipalRequest';
import { EnterpriseGatewayApiRequest } from '../../../types/enterpriseGatewayRequest';
import { GitHubRepoRequest } from '../../../types/gitHubRepoRequest';

export enum BaseAppArchetype {
    AppProvisioning,
    Apigee,
    Package,
    SalesforceDxPackage,
    ConsoleApp,
    ApplicationTransfer,
    CloudAccount,
    AzureSecurityGroups,
    AzureTagging,
    IamRequest,
    ServicePrincipalRequest,
    EnterpriseGatewayApi,
    GitHubRepo,
    OnrampWidget,
}

export enum NewAppStep {
    BasicInfo,
    ProxyConfig,
    SalesforceDxConfig,
    GitHubCodeGen,
    GitHubTeam,
    GitHubRepositories,
    AzureSubscription,
    AzureResourceGroups,
    AzureResourceProvisioning,
    ContinuousIntegration,
    Onramp,
    EnterpriseMessaging,
    CloudAccountRequest,
    AzureSecurityGroups,
    AzureTaggingSelectResources,
    AzureTaggingUpdateTags,
    IamRequest,
    ServicePrincipalRequest,
    EnterpriseGatewayRequestType,
    EnterpriseGatewayApi,
    EnterpriseGatewaySubscribeToApi,
    EnterpriseGatewayApiPermissions,
    ApplicationTransferType,
    Review,
}

export enum AppArchetype {
    DotNetService = 1,
    DotNetReactSite,
    NpmPackage,
    NuGetPackage,
    ApigeeProxy,
    AzureSubscriptionOrResourceGroups,
    AzureResourceGroupsOnly,
    CiOnly,
    GitHubRepo,
    StaticSite,
    ArmTemplate,
    SalesForceDX,
    DotNetConsole,
    ApplicationTransfer,
    MinimalApi,
    AzureSecurityGroups,
    CloudAccountRequest,
    AzureTagging,
    IamRequest,
    ServicePrincipalRequest,
    EnterpriseGatewayApi,
}

export type AppArchetypeDetails = {
    appArchetype: AppArchetype;
    baseAppArchetype: BaseAppArchetype;
    appArchetypeDescription: string;
};

export type StepDetail = {
    id: NewAppStep;
    description: string;
};

export type SetAzureOptionsActionPayload =
    | {
          needSubscription: ResourceNeedType;
          azureSubscriptionName: string;
          azureSubscriptionId: string;
          ownerVPName: string;
          ownerVPEmail: string;
          azureSubscriptionBusinessJustification: string;
          doesSubscriptionRequireOnPremAccess: boolean;
      }
    | {
          resourcesProjectName: string;
          applicationShortName: string;
          azureResourcesLanguage: AzureResourcesLanguage;
          gitHubAzureResourcesRepositoryName: string;
      };

export type SetCiOptionsActionPayload = {
    needDevOpsProject: ResourceNeedType;
    devOpsProjectName: string;
    devOpsProjectId: string;
    AdoGithubServiceAccount: string;
    subscriptionId: string;
    subscriptionName: string;
    resourceGroups: CIOnlyResourceGroup[];
};

export type SpoNeedInfo = {
    needSpoGroups: ResourceNeedType;
    spoSecurityGroupId: string;
    spoSecurityGroupName: string;
};

export type SetSpoGroupsActionPayload = {
    devSpoInfo: SpoNeedInfo;
    qaSpoInfo: SpoNeedInfo;
    prodSpoInfo: SpoNeedInfo;
};

export type SetSecurityGroupsActionPayload = {
    prodPowerUsersSecurityGroups: SecurityGroupInfo[];
    prodLimitedAccessSecurityGroups: SecurityGroupInfo[];
    nonProdPowerUsersSecurityGroups: SecurityGroupInfo[];
    nonProdLimitedAccessSecurityGroups: SecurityGroupInfo[];
};

export type InProgressApplicationRequest = ApplicationProvisioningRequest & { kind: BaseAppArchetype.AppProvisioning };
export type InProgressPackageRequest = PackageRequest & { kind: BaseAppArchetype.Package };
export type InProgressApigeeRequest = ApigeeRequest & { kind: BaseAppArchetype.Apigee };
export type InProgressSalesforceDxRequest = SalesforceDxRequest & { kind: BaseAppArchetype.SalesforceDxPackage };
export type InProgressConsoleAppRequest = ConsoleApplicationRequest & { kind: BaseAppArchetype.ConsoleApp };
export type InProgressApplicationTransferRequest = ApplicationTransferRequest & {
    kind: BaseAppArchetype.ApplicationTransfer;
};
export type InProgressCloudAccountRequest = CloudAccountRequest & {
    kind: BaseAppArchetype.CloudAccount;
};
export type InProgressAzureTaggingRequest = AzureTaggingRequest & {
    kind: BaseAppArchetype.AzureTagging;
};
export type InProgressAzureSecurityGroupsRequest = AzureSecurityGroupsRequest & {
    kind: BaseAppArchetype.AzureSecurityGroups;
};
export type InProgressIamRequest = IamRequest & {
    kind: BaseAppArchetype.IamRequest;
};
export type InProgressServicePrincipalRequest = ServicePrincipalRequest & {
    kind: BaseAppArchetype.ServicePrincipalRequest;
};

export type InProgressEnterpriseGatewayApiRequest = EnterpriseGatewayApiRequest & {
    kind: BaseAppArchetype.EnterpriseGatewayApi;
};

export type InProgressGitHubRepoRequest = GitHubRepoRequest & {
    kind: BaseAppArchetype.GitHubRepo;
};

export type InProgressProvisioningRequest = Partial<
    | InProgressApplicationRequest
    | InProgressPackageRequest
    | InProgressApigeeRequest
    | InProgressSalesforceDxRequest
    | InProgressConsoleAppRequest
    | InProgressApplicationTransferRequest
    | InProgressCloudAccountRequest
    | InProgressAzureTaggingRequest
    | InProgressAzureSecurityGroupsRequest
    | InProgressIamRequest
    | InProgressServicePrincipalRequest
    | InProgressEnterpriseGatewayApiRequest
    | InProgressGitHubRepoRequest
>;

export type InProgressRequest = {
    index: number;
    id: string;
    modifiedUtc?: string;
    submitter: UserInfo;
    request: InProgressProvisioningRequest;
    appArchetypeDetails: AppArchetypeDetails;
    currentStep?: NewAppStep;
    steps: StepDetail[];
    maxStepIndex: number;
    props: PropertyBag;
};

type PropertyBag = Record<string, boolean | string | number | undefined>;

export type CreateProvisioningRequestResponse = {
    index: number;
    id: string;
    success: boolean;
    errorMessages: string[];
    requestId?: string;
};

export type ProvisioningRequestSubmission = { draftRequestId: string } & (
    | {
          applicationProvisioningRequest: Partial<ApplicationProvisioningRequest>;
      }
    | {
          apigeeRequest: Partial<ApigeeRequest>;
      }
    | {
          npmPackageRequest: Partial<PackageRequest>;
      }
    | {
          nuGetPackageRequest: Partial<PackageRequest>;
      }
    | {
          salesforceDxRequest: Partial<SalesforceDxRequest>;
      }
    | {
          consoleApplicationRequest: Partial<ConsoleApplicationRequest>;
      }
    | {
          applicationTransferRequest: Partial<ApplicationTransferRequest>;
      }
    | {
          cloudAccountRequest: Partial<CloudAccountRequest>;
      }
    | {
          azureSecurityGroupsRequest: Partial<AzureSecurityGroupsRequest>;
      }
    | {
          azureTaggingRequest: Partial<AzureTaggingRequest>;
      }
    | {
          iamRequest: Partial<IamRequest>;
      }
    | {
          servicePrincipalRequest: Partial<ServicePrincipalRequest>;
      }
    | {
          enterpriseGatewayRequest: Partial<EnterpriseGatewayApiRequest>;
      }
    | {
          gitHubRepoRequest: Partial<GitHubRepoRequest>;
      }
);
