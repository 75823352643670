import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AzureCostGroupFilter, AzureCostDateRangeFilter } from '../components';
import { DateTime } from 'luxon';
import { CostGroup, QueryData } from '../types';
import { isEqual } from 'lodash';
import qs from 'qs';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';

type AzureCostFiltersProps = {
    groups: CostGroup[];
    queryData: (data: QueryData) => void;
};

const useStyles = makeStyles({
    filters: {
        paddingTop: "15px",
        paddingBottom: "10px"
    }
});

export const AzureCostFilters = ({ groups, queryData }: AzureCostFiltersProps) => {
    const [detailLevel, setDetailLevel] = useState('program');
    const [startDateRange, setStartDateRange] = useState<DateTime>(DateTime.now().minus({ years: 1 }).set({ day: 1 }));
    const [endDateRange, setEndDateRange] = useState<DateTime>(DateTime.now().minus({ days: 1 }));

    const [programs, setPrograms] = useState<string[]>(['']);
    const [owners, setOwners] = useState<string[]>(['']);
    const [resources, setResources] = useState<string[]>(['']);

    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
    const [selectedResources, setSelectedResources] = useState<string[]>([]);

    const [localQueryData, setLocalQueryData] = useState<QueryData>();

    const classes = useStyles();

    const identityApi = useApi(identityApiRef);

    const arrayify = (obj: any): string[] => {
        let newArr = [];
        if (typeof obj === 'string') {
            newArr.push(obj);
        } else {
            newArr = obj;
        }
        return newArr;
    };

    // update lists when groups changes
    useEffect(() => {
        const getCostGroupData = async () => {
            const newPrograms = groups.map(x => x.id);
            setPrograms(newPrograms);
            setOwners(groups.flatMap(x => x.members));
            setResources(groups.flatMap(x => x.resourceProviders));
        };

        const setCostGroupDataSelections = async () => {
            const windowLocation = window.location.search.substring(1);
            const params = qs.parse(windowLocation);

            // these have defaults, no need to set if params are null
            if (params.startDate !== undefined)
                setStartDateRange(DateTime.fromFormat(params.startDate as string, 'yyyy-MM-dd'));
            if (params.endDate !== undefined)
                setEndDateRange(DateTime.fromFormat(params.endDate as string, 'yyyy-MM-dd'));

            if (Object.keys(params).length === 0) {
                const user = (await identityApi.getProfileInfo()).email;
                const userGroups = groups.filter(x => x.members.indexOf(user!.toLowerCase()) !== -1);
                if (userGroups.length > 0) {
                    // user is a member in a group
                    // set defaults to that person
                    const userResources = userGroups.flatMap(x => x.resourceProviders);
                    const userGroupNames = userGroups.map(x => x.id);

                    // startDate is already set
                    // endDate is already set
                    setDetailLevel('owner');
                    setSelectedPrograms(userGroupNames);
                    setSelectedOwners(arrayify(user));
                    setSelectedResources(userResources);
                } // user isn't an owner, use default values for everything
                else {
                    // startDate is already set
                    // endDate is already set
                    // detail level is already 'program'
                    setSelectedPrograms(groups.map(x => x.id));
                    setSelectedOwners(groups.flatMap(x => x.members));
                    setSelectedResources(groups.flatMap(x => x.resourceProviders));
                }
            } // params have been specified, but use defaults where needed
            else {
                // detail level is already set, override if specified in params
                if (params.detailLevel !== undefined) setDetailLevel(params.detailLevel as string);

                // get selected programs from default or params
                const paramSelectedPrograms =
                    params.programs === undefined ? [groups[0].id] : arrayify(params.programs);
                // since the params reflect the hierarchy i.e. we show program but owners are implied
                // we grab the groups object and use that to base our defaults for owners and resources
                const selectedProgramObjects = groups.filter(x => paramSelectedPrograms.indexOf(x.id) !== -1);
                const selectedProgramOwnerDefaults = selectedProgramObjects.flatMap(x => x.members);
                const selectedProgramResourceDefaults = selectedProgramObjects.flatMap(x => x.resourceProviders);

                const paramSelectedOwners =
                    params.owners === undefined ? selectedProgramOwnerDefaults : arrayify(params.owners);
                const paramSelectedResources =
                    params.resources === undefined ? selectedProgramResourceDefaults : arrayify(params.resources);

                setSelectedPrograms(paramSelectedPrograms);
                setOwners(selectedProgramOwnerDefaults);
                setSelectedOwners(paramSelectedOwners);
                setSelectedResources(paramSelectedResources);
            }
        };

        if (groups.length > 0) {
            // eslint-disable-next-line no-console
            getCostGroupData().catch(console.error);
            // eslint-disable-next-line no-console
            setCostGroupDataSelections().catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    // update downstream list when program changes
    const updateSelectedPrograms = useCallback(
        (selPrograms: string[]) => {
            // update the owners select based on changes to selected programs
            // get the list of groups
            const selectedGroups = groups.filter(x => selPrograms.indexOf(x.id) !== -1);
            // get the list of owners
            const newOwners = selectedGroups.flatMap(x => x.members);
            // get the list of resources
            const newResources = selectedGroups.flatMap(x => x.resourceProviders);
            const uniqueResources = [...new Set(newResources)];
            // just set the selected programs based on callback data
            setSelectedPrograms(selPrograms);
            // set the owners in the select
            setOwners(newOwners);
            // just reset selectedOwners, don't try to persist current selections. It's not the best ux but will do for now.
            setSelectedOwners(newOwners);
            // set the resources in the select
            setResources(uniqueResources);
            // just reset selectedResources, don't try to persist current selections. It's not the best ux but will do for now.
            setSelectedResources(uniqueResources);
        },
        [groups],
    );

    const isQueryValid = (data: QueryData) => {
        let isValid = false;
        switch (data.detailLevel) {
            case 'program': {
                isValid = data.programs.length > 0 ? true : false;
                break;
            }
            case 'owner': {
                isValid = data.owners.length > 0 ? true : false;
                break;
            }
            case 'resource': {
                isValid = data.resources.length > 0 ? true : false;
                break;
            }
            default: {
                break;
            }
        }
        return isValid;
    };

    const setUrlAndQueryString = useCallback((data: QueryData) => {
        const strQueryData = {
            startDate: data.startDate.toFormat('yyyy-MM-dd'),
            endDate: data.endDate.toFormat('yyyy-MM-dd'),
            detailLevel: data.detailLevel,
            programs: data.programs,
            owners: data.detailLevel !== 'program' ? data.owners : null,
            resources: data.detailLevel !== 'program' && data.detailLevel !== 'owner' ? data.resources : null,
        };

        const urlQueryStr = qs.stringify(strQueryData, { indices: false, skipNulls: true });
        const windowLocation = window.location;
        window.history.replaceState(null, '', `${windowLocation.protocol}//${windowLocation.host}/cost?${urlQueryStr}`);
    }, []);

    // modify query based on changes
    useEffect(() => {
        const newQueryData = {
            startDate: startDateRange,
            endDate: endDateRange,
            detailLevel: detailLevel,
            programs: selectedPrograms,
            owners: selectedOwners,
            resources: selectedResources,
        };
        if (isQueryValid(newQueryData) && isEqual(newQueryData, localQueryData) === false) {
            queryData(newQueryData);
            setLocalQueryData(newQueryData);
            setUrlAndQueryString(newQueryData);
        }
    }, [
        detailLevel,
        endDateRange,
        localQueryData,
        queryData,
        selectedOwners,
        selectedPrograms,
        selectedResources,
        setUrlAndQueryString,
        startDateRange,
    ]);

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            onChange={e => setDetailLevel(e.target.value as string)}
                            value={detailLevel}
                            label="Detail Level"
                            labelId="label-for-detail-select"
                        >
                            <MenuItem value="program" key="1">
                                Program
                            </MenuItem>
                            <MenuItem value="owner" key="2">
                                Owner
                            </MenuItem>
                            {/* <MenuItem value="resource" key="3">
                                Resource
                            </MenuItem> */}
                        </Select>
                        <InputLabel id="label-for-detail-select">Detail Level</InputLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <AzureCostDateRangeFilter
                                dateLabel="Start Date"
                                dateValue={startDateRange}
                                updateDateValue={setStartDateRange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AzureCostDateRangeFilter
                                dateLabel="End Date"
                                dateValue={endDateRange}
                                updateDateValue={setEndDateRange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.filters} container>
                <Grid item xs={4}>
                    <AzureCostGroupFilter
                        groupLabel="Programs"
                        groupList={programs}
                        selectedGroups={selectedPrograms}
                        setSelectedGroups={updateSelectedPrograms}
                    />
                </Grid>
                <Grid item xs={4}>
                    {detailLevel !== 'program' ? (
                        <AzureCostGroupFilter
                            groupLabel="Owners"
                            groupList={owners}
                            selectedGroups={selectedOwners}
                            setSelectedGroups={setSelectedOwners}
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {detailLevel === 'resource' ? (
                        <AzureCostGroupFilter
                            groupLabel="Resources"
                            groupList={resources}
                            selectedGroups={selectedResources}
                            setSelectedGroups={setSelectedResources}
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
